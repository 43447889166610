import './App.css';
// import LandingPage from './pages/LandingPage/LandingPage';

function App() {
  return (
    <div className='container'>
      
      {/* <LandingPage /> */}
    </div>
  );
}

export default App;
